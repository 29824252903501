import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config'
// import 'normalize.css';
import './static/css/reset.css'
import './static/js/rem.js'
import { GlobalStyles } from './static/css/GlobalStyle';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>
);

