import Arial from './arialbd.ttf';
import Helvetica from './Helvetica.ttf';
// import HelveticaBold from './HelveticaBold.ttf';
import SourceRegular from './SourceHanSansCN-Regular.otf';
// import SourceBold from './SourceHanSansCN-Bold.otf';

export const fonts = `

  @font-face {
    font-family: 'Arial';
    src: url('${Arial}') format('truetype');
    font-style: normal;
    font-weight: default;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('${Helvetica}') format('truetype');
    font-weight: default;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceHanSansCN';
    src: url('${SourceRegular}') format('OpenType');
    font-weight: default;
    font-style: normal;
  }
 `;
