import styled from 'styled-components';
import { Grid, Cell } from '../grid';

const CardContainer = styled.div`
  max-width: 990px;
  margin: 0 auto;
`;

const InfoBox = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;

  img{
    width: 100%;
  }
`;

const Description = styled.div`
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
  padding: 18px 16px;
  height: 40px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 0 0 5px 5px;
`;

function FeatureCard(props) {
  const { items } = props;

  const toWebsite = (link) => {
    console.log(link)
    window.open(link, "_blank")
  }
  return (
    <CardContainer>
      <Grid columns={3} columnGap={'30px'} rowGap={'30px'}>
        {items.map((item, index) => {
          return (
            <Cell key={index} xs={3} sm={1}>
              <InfoBox onClick={() => toWebsite(item.link)}>
                  <img src={item.icon} alt="news" />
                <Description>{item.description}</Description>
              </InfoBox>
            </Cell>
          );
        })}
      </Grid>
    </CardContainer>
  );
}

export { FeatureCard };
