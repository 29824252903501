import styled from 'styled-components';

const Cell = styled.div`
  min-width: 0;
  grid-column-end: ${({ xs = 1 }) => `span ${xs}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};

  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};

  ${({ center }) => center && `text-align: center`};

  ${({ area }) => area && `grid-area: ${area}`};

  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`}

  ${({ middle }) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `};

  @media (min-width: 768px) {
    ${({ sm }) => sm && `
      grid-column-end: span ${sm};
    `}
  }

  @media (min-width: 1160px) {
    ${({ md }) => md && `
      grid-column-end: span ${md};
    `}
  }
`;

export { Cell };
