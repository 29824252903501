; (function rem() {
    // let dw = 750  // 设计图宽度
    // let sw = window.screen.width // 屏幕宽度
    let cw = document.documentElement.clientWidth;
    let fontSize = (cw / 100)
    let oHtml = document.getElementsByTagName('html')[0]
    oHtml.style.fontSize = fontSize + 'px'
    // 设置边界
    const boundary = () => {
        if (cw < 1200) {
            oHtml.style.fontSize = 12 + 'px'
        }
    }
    boundary()

    window.onresize = function (event) {
        rem();
        boundary();
    }
})();