import styled from 'styled-components';
import { Grid, Cell } from '../grid';

const InfoBoxSectionContainer = styled.div`
  max-width: 990px;
  margin: 0 auto;
  padding: 0 25px;
`;

const InfoBox = styled.div`
  height: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 40px;
`;

const IconContainer = styled.div`
  img {
    height: 48px;
  }
`;
const Name = styled.div`
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.88px;
    line-height: 30px;
    margin: 25px 0 21px;
    color: #373B46;
`

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #7C7F87;
  line-height: 25px;
`;

function FeatureBoxSection(props) {
  const { items } = props;

  return (
    <InfoBoxSectionContainer>
      <Grid columns={4} columnGap={'30px'} rowGap={'30px'}>
        {items.map((item, index) => {
          return (
            <Cell key={index} xs={4} sm={1}>
              <InfoBox>
                <IconContainer>
                  <img src={item.icon} />
                </IconContainer>
                <Name>{item.name}</Name>
                <Description>{item.description}</Description>
              </InfoBox>
            </Cell>
          );
        })}
      </Grid>
    </InfoBoxSectionContainer>
  );
}

export { FeatureBoxSection };
