import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import Header from '../components/Header';
import Title from '../components/common/Title';
import VideoPlayer from '../components/common/VideoPlayer';
import { FeatureBoxSection } from '../components/common/FeatureBoxSection';
import Faq from '../components/common/Faq'
import '../static/css/Home.css'
import tronLogo from '../static/images/tron_logo.png'
import stableLogo from '../static/images/stable.png'
import transLogo from '../static/images/transparent.png'
import safeLogo from '../static/images/safe.png'
import vaultLogo from '../static/images/vault.png'
import homeVideo from '../static/video/home.mp4'
import homeBanner from '../static/images/home_banner.jpg'
import { useState } from 'react';
import tcnhEnVideo from '../static/video/TCNH-video-en.mp4'
import tcnhCnVideo from '../static/video/TCNH-video-cn.mp4'

const Content = styled.div`
`
const Banner = styled.div`
    position:relative;
    width: 100%;
    height: 26.04rem;
    background: #000;
`
const VideoWrap = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`
const BannerWrap = styled.div`
    position:relative;
    width: 100%;
`

const BannerTitle = styled.h2`
    font-size: 2.1rem;
    line-height: 5.46875rem;
    color: #fff;
    font-family: Helvetica, Arial;
    padding: 4rem 0 0 8.5rem;
    font-weight: 600;
`

const BannerDesc = styled.p`
    font-size: 1.375rem;
    line-height: 3.125rem;
    color: #fff;
    font-family: Helvetica;
    padding: 2rem 0 0 8.5rem;
    width: 30rem;
    font-weight: 100;
`

const tcnhFeature =  {
    en: [
        {
            "icon": stableLogo,
            "name": "Stable",
            "description": "TCNH price is pegged to offshore Chinese Yuan"
        },
        {
            "icon": transLogo,
            "name": "Transparent",
            "description": "All TCNH tokens are pegged to Offshore RMB and are backed 100% by reserves"
        },
        {
            "icon": safeLogo,
            "name": "Safe",
            "description": "Contracts are being audited by top auditing company"
        },
        {
            "icon": vaultLogo,
            "name": "Vault",
            "description": "Promoting the optimal allocation of diversified assets in the world"
        }
    ],
    zh: [
        {
            "icon": stableLogo,
            "name": "稳定",
            "description": "TCNH锚定离岸人民币，价格稳定"
        },
        {
            "icon": transLogo,
            "name": "透明度",
            "description": "所有 TCNH 代币都与离岸人民币挂钩，100% 储备金支持"
        },
        {
            "icon": safeLogo,
            "name": "安全性",
            "description": "合约正由顶级审计公司进行审计"
        },
        {
            "icon": vaultLogo,
            "name": "金库",
            "description": "推动全球资本优化配置"
        }
    ]
}
const faqFeature = {
    "en": [
        {
            question: "What is the stablecoin TCNH?",
            answer: "TCNH is a stablecoin that is pegged 1:1 to the offshore Chinese yuan.",
        },
        {
            question: "Which public blockchains support the stablecoin TCNH?",
            answer: "TCNH currently only developed on TRON network, and will expand to more networks in the future.",
        },
        {
            question: "What is the contract address of TCNH?",
            answer: "TBqsNXUtqaLptVK8AYvdPPctpqd8oBYWUC",
        },
        {
            question: "How can I purchase the stablecoin TCNH? Which exchanges are available?",
            answer: "Currently, TCNH only supports corporate account registration, and you can register as an institutional user through the official website truecnh.io. In addition to the official website, you can also obtain TCNH from Huobi exchange. For more ways to obtain TCNH, please follow TCNH's social media to get the latest news.",
        },
        {
            question: "Will the reserve of the stablecoin TCNH be audited? Which auditing company will perform the audit?",
            answer: "The reserve of TCNH will be audited by a top auditing firm. Please stay tuned to our official website for more information on the audit.",
        }
    ],
    "zh": [
        {
            question: "稳定币TCNH是什么？",
            answer: "TCNH是1:1锚定离岸人民币的稳定币产品。",
        },
        {
            question: "哪些公链支持稳定币TCNH？",
            answer: "TCNH目前仅选择了波场TRON网络进行合约部署，未来会扩展至更多公链。",
        },
        {
            question: "TCNH合约地址是多少？",
            answer: "TBqsNXUtqaLptVK8AYvdPPctpqd8oBYWUC",
        },
        {
            question: "如何购买稳定币TCNH？上线了哪些交易所？",
            answer: "TCNH目前支持机构用户注册，您可以通过官网truecnh.io注册机构用户，除官网外，您还可以至火必交易所获取TCNH，更多获取方式请关注TCNH社交媒体。",
        },
        {
            question: "稳定币TCNH的储备金会经过审计吗？由哪家审计公司进行？",
            answer: "TCNH的储备金将会由顶级审计机构进行审计，请您关注我们的官网获取更多审计信息。",
        }
    ]
}
function Home() {
    const { t } = useTranslation()
    const [faqShow, setFaqShow] = useState([true, false, false, false, false])
    const changeShow = (i) => {
        const newArray = [...faqShow];
        newArray[i] = !newArray[i];
        setFaqShow(newArray)
    }
    return (
        <div>
            <Header active="home"></Header>
            <Content>
                <Banner>
                    <VideoWrap>
                        <video
                            width="100%"
                            height="100%"
                            autoPlay
                            loop
                            muted
                            poster={homeBanner}
                            preload='auto'
                        >
                            <source src={homeVideo} type="video/mp4"></source>
                            Your browser does not support this control, please upgrade your browser!
                        </video>
                    </VideoWrap>
                    <BannerWrap>
                        <BannerTitle>{t('home.bannerTitle')}</BannerTitle>
                        <BannerDesc>{t('home.bannerDesc')}</BannerDesc>
                    </BannerWrap>
                </Banner>
                <Title title={t('home.tcnhVideoTitle')} />
                {t('home.lang') === 'en'
                ? <VideoPlayer url={tcnhEnVideo} />
                : <VideoPlayer url={tcnhCnVideo} />
                }
                <Title title={t('home.addressTitle')} />
                <div className='contract_container'>
                    <img src={tronLogo} alt="tron tcnh contract address" />
                    <a href="https://tronscan.org/#/token20/TBqsNXUtqaLptVK8AYvdPPctpqd8oBYWUC" target='_blank' rel="noopener noreferrer">TBqsNXUtqaLptVK8AYvdPPctpqd8oBYWUC</a>
                </div>
                <Title title={t('home.whyTcnhTitle')} />
                <FeatureBoxSection items={t('home.lang') === 'en' ? tcnhFeature.en : tcnhFeature.zh} />
                <Title title={t('home.faqsTitle')} />
                <Faq changeShow={(i) => changeShow(i)} faqShow={faqShow} items={t('home.lang') === 'en' ? faqFeature.en : faqFeature.zh} />
            </Content>
        </div>
    )
}

export default Home;