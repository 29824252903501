import { useState } from "react";
import styled, {keyframes} from "styled-components";
import iconUp from '../../static/images/icon-up.png'
import iconDown from '../../static/images/icon-down.png'

// const iconRotate = keyframes`
// to {
//     transform: rotate(180deg);
// }
// `

const FaqBoxSectionContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 0 25px;
`;

const FaqBox = styled.div`
    padding: 16px 0;
    border-bottom: 1px solid #E9EDF2;
`

const Question = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #08121E;
    line-height: 30px;
    margin-bottom: 15px;
    background-image: url(${props => props.icon});
    background-size: 12px 7px;
    background-repeat: no-repeat;
    background-position: right 15px;
    cursor: pointer;
    padding: 0 20px 0 0;
`

const Answer = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #7C7F87;
    color: #7C7F87;
    line-height: 20px;
    padding: 0 20px 0 0;
`
function Faq(props) {
    const { items, changeShow, faqShow } = props;
    return(
        <FaqBoxSectionContainer>
            {items.map((v, i) => {
                return (
                    <FaqBox key={i}>
                        <Question onClick={() => changeShow(i)} icon={faqShow[i]? iconUp : iconDown}>
                            {v.question}
                        </Question>
                        {
                        faqShow[i] && (
                        <Answer>
                            {v.answer}
                        </Answer>
                        )
                        }
                    </FaqBox>
                )
            })}
        </FaqBoxSectionContainer>
    )
}

export default Faq;