import styled from 'styled-components';

const TitleWrapper = styled.div`
    text-align: center;
    font-size: 28px;
    margin: 50px 0 35px;
    font-weight: 500;
    color: #08121E;
    line-height: 40px;
`

function Title(props) {
    return(
        <TitleWrapper>{props.title}</TitleWrapper>
    )
}

export default Title;