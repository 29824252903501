import styled from 'styled-components';

const YoutubeVideoWrapper = styled.div`
  video {
    display: block;
    margin: 0 auto;
  }
`;

function VideoPlayer(props) {
  const videoUrl = props.url;

  return (
    <YoutubeVideoWrapper>
      <video
        width="700"
        height="400"
        controls
        key={videoUrl}
        preload='auto'
        >
          <source src={`${videoUrl}`} type="video/mp4"></source>
          Your browser does not support this control, please upgrade your browser!
      </video>
    </YoutubeVideoWrapper>
  );
}

export default VideoPlayer;
