import '../static/css/Footer.css'
import tcnhLogo from '../static/images/tcnh.png'


function Footer () {
    return (
        <div className='footer_container'>
            <div className='footer_box'>
                <div className='footer_left'>
                    <div className='header_logo_box'>
                        <img className='header_logo' src={tcnhLogo} alt="tcnh logo" />
                        <span>TCNH</span>
                    </div>
                    <div className='copyright'>© 2023 TrueCNH. All rights reserved.</div>
                </div>
                <ul className='footer_right'>
                    <a href="https://twitter.com/TCNH_Official" target="_blank" rel="noopener noreferrer"><li className='twitter'></li></a>
                    <a href="https://medium.com/@TCNH_Official" target="_blank" rel="noopener noreferrer"><li className='medium'></li></a>
                    <a href="https://weibo.com/7809715256" target="_blank" rel="noopener noreferrer"><li className='weibo'></li></a>
                </ul>
            </div>
        </div>
    )
}

export default Footer;