import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import '../static/css/Header.css';
import tcnhLogo from '../static/images/tcnh.png'
import { useState } from 'react';

function Header(props) {
    const { active } = props
    const [lang, setLang] = useState('en');
    const { t } = useTranslation();

    const changeLang = (val) => {
        console.log('val', val)
        if (val === 'en') {
            setLang('zh')
            i18n.changeLanguage('zh')
        } else {
            setLang('en')
            i18n.changeLanguage('en')
        }
    }

    return(
        <div className='header_container'>
            <Link to="/" className='header_logo_box'>
                <img className='header_logo' src={tcnhLogo} alt="tcnh logo" />
                <span>TCNH</span>
            </Link>
            <ul className='header_nav'>
                <li><Link to="/" className={active === 'home' ? 'active' : ''}>{t('header.home')}</Link></li>
                <li><Link to="/about" className={active === 'about' ? 'active' : ''}>{t('header.about')}</Link></li>
            </ul>
            <div>
                <button className='header_lang button' onClick={() => changeLang(lang)}>{t('header.langButton')}</button>
                <a href='https://app.truecnh.io/' target='_blank' rel="noopener noreferrer" className='header_app button'>{t('header.appButton')}</a>
            </div>
        </div>
    )
}

export default Header;