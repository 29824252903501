import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import Header from '../components/Header';
import Title from '../components/common/Title';
import { FeatureCard } from '../components/common/FeatureCard';
import teamLogo from '../static/images/team_logo.png'
import learnMore1 from "../static/images/learn_more_1.png"
import learnMore2 from "../static/images/learn_more_2.png"
import learnMore1Cn from '../static/images/learn_more_1_cn.png'
import learnMore2Cn from '../static/images/learn_more_2_cn.png'
import partnerLogo from '../static/images/partner_logo.png'
import partnerBg from '../static/images/partner_bg.png'

const Content = styled.div`
    max-width: 990px;
    margin: 0 auto;
    padding: 50px 0;
    padding: ${props => props.padding};
`
const Grey = styled.div`
    background: #F9FAFB;
`
const TeamContainer = styled.div`
    display: flex;
    justify-content: space-between;
    img {
        width: 260px;
    }
`

const TeamLeftBox = styled.div`
    margin-right: 129px;
`
const TitleHalf = styled.div`
    width: 382px;
    font-size: 28px;
    font-weight: 600;
    color: #000000;
    line-height: 33px;
    margin-bottom: 25px;
`
const Desc = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #7C7F87;
    line-height: 20px;
    margin-bottom: ${props => props.mb};
`
const Button = styled.a`
    height: 41px;
    background: #1B5AFE;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    outline: none;
    text-transform: uppercase;
    line-height: 41px;
    cursor: pointer;
    padding: 0 40px;
    display: inline-block;
    text-decoration: none;

    &:hover {
        background: #1850E4;
    }
`

const Box = styled.div`
    img{
        margin-right: 60px;
        width: 260px;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
`

const JoinUsBox = styled.div`
    display: flex;
    justify-content: space-between;
    background: #0A1B48;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    padding: 50px 70px;
    background-image: url(${partnerBg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
`

const JoinUsDesc = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 41px;
`

const cardObj =  {
    en: [
        {
            "icon": learnMore1,
            "description": "Chinese Yuan Pegged Stablecoin TCNH Officially Launched on TRON",
            "link": "https://medium.com/@TCNH_Official/chinese-yuan-pegged-stablecoin-tcnh-officially-launched-on-tron-c4e054838719"
        },
        {
            "icon": learnMore2,
            "description": "Huobi Exchange Lists TCNH, a Stablecoin Pegged to Offshore Chinese Yuan",
            "link": "https://medium.com/@TCNH_Official/huobi-exchange-lists-tcnh-a-stablecoin-pegged-to-offshore-chinese-yuan-516423d435fb"
        },
    ],
    zh: [
        {
            "icon": learnMore1Cn,
            "description": "离岸人民币稳定币TCNH在波场TRON上被正式推出",
            "link": "https://medium.com/@TCNH_Official/chinese-yuan-pegged-stablecoin-tcnh-officially-launched-on-tron-c4e054838719"
        },
        {
            "icon": learnMore2Cn,
            "description": "离岸人民币稳定币TCNH现已首发上线火必交易所",
            "link": "https://medium.com/@TCNH_Official/huobi-exchange-lists-tcnh-a-stablecoin-pegged-to-offshore-chinese-yuan-516423d435fb"
        },
    ]
}
function About() {
    const { t } = useTranslation()
    return (
        <>
            <Header active="about"></Header>
            <Content>
                <TeamContainer>
                    <TeamLeftBox>
                        <TitleHalf>{t('about.team_title')}</TitleHalf>
                        <Desc mb="20px">{t('about.team_desc')}</Desc>
                        <Desc>{t('about.team_desc_1')}</Desc>
                    </TeamLeftBox>
                    <img src={teamLogo} alt="team" />
                </TeamContainer>
            </Content>
            <Grey>
                <Content>
                    <TitleHalf>{t('partner.title_part_one')}</TitleHalf>
                    <Desc mb="30px">{t('partner.desc_part_one')}</Desc>
                    <Button href='https://docs.google.com/forms/d/17StrmzUbiWfUAaom0BMpwmtBNIQIc_5QaVba2pXvW0w/edit' target='_blank' rel="noopener noreferrer">{t('partner.become_partner_button')}</Button>
                </Content>
            </Grey>
            <Content padding="0">
                <Title title={t('partner.title_part_two')} />
                <Box>
                    <img src={partnerLogo} alt="partner" />
                    <Desc>{t('partner.desc_part_two')}</Desc>
                </Box>
                <JoinUsBox>
                    <JoinUsDesc>{t('partner.desc_part_three')}</JoinUsDesc>
                    <Button href='https://docs.google.com/forms/d/17StrmzUbiWfUAaom0BMpwmtBNIQIc_5QaVba2pXvW0w/edit' target='_blank' rel="noopener noreferrer">{t('partner.join_us_button')}</Button>
                </JoinUsBox>
                <Title title={t('about.news_title')}></Title>
                <FeatureCard items={t('lang') === 'en' ? cardObj.en : cardObj.zh} />
            </Content>
        </>
    )
}

export default About;